import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeCourse from '../views/HomeCourse.vue'
import HomeAbout from '../views/HomeAbout.vue'
import Login from  '../views/login/index.vue'

//解决报错：Navigation aborted from “/a“ to “/b“ via a navigation guard
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  // 安检系统
  {
    path: '/imagetrain',
    component: () => import('@/views/security_imagetrain/index.vue'),
    name: 'imagetrain',
    meta: [{ title: '图像练习',breadcrumb: true }]
  },
  {
    path: '/knowledgetrain',
    component: () => import('@/views/security_knowledgetrain/index.vue'),
    name: 'knowledgetrain',
    meta: [{ title: '知识练习',breadcrumb: true }]
  },
  {
    path: '/learntime',
    component: () => import('@/views/security_learntime/index.vue'),
    name: 'learntime',
    meta: [{ title: '学习时长',breadcrumb: true }]
  },
  {
    path: '/imagestore',
    component: () => import('@/views/security_imagestore/index.vue'),
    name: 'imagestore',
    meta: [{ title: '元素图库',breadcrumb: true }]
  },
  {
    path: '/mytrainingclass',
    component: () => import('@/views/security_mytrainingclass/index.vue'),
    name: 'mytrainingclass',
    meta: [{ title: '我的培训' }]
  },
  {
    path: '/mytrainingclass/courselist',
    component: () => import('@/views/security_trainingclass/index.vue'),
    name: 'mytrainingclass_courselist',
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true },
      { title: '课程列表' }
    ]
  },
  {
    path: '/mytrainingclass/trainexamlist',
    component: () => import('@/views/security_trainingclass_trainexamlist/index.vue'),
    name: 'mytrainingclass_trainexamlist',
    meta: [ 
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true },
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] }, // params: class_id 上一级参数 ['hkr_class_id'] 本页参数，但是是此上级路由接收参数
    { title: '练习列表' }
  ]
  },
  {
    path: '/course/plist/train/select',
    name: 'CourseTrainSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true },
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] }, // params: class_id 上一级参数 ['hkr_class_id'] 本页参数，但是是此上级路由接收参数
    { title: '练习列表',url: "/mytrainingclass/trainexamlist", breadcrumb: true, routeparams: [{hkr_class_id:'hkr_class_id'},{type:'hkr_type'},{course_name:'hkr_course_name'},{course_id:'hkr_course_id'}] },
      { title: '试卷页面', breadcrumb: true }
    ],
  },
  {
    path: '/course/plist/scoreshow',
    name: 'CoursePlistScoreShow',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_scoreshow/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true },
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] }, // params: class_id 上一级参数 ['hkr_class_id'] 本页参数，但是是此上级路由接收参数
    { title: '练习列表',url: "/mytrainingclass/trainexamlist", breadcrumb: true, routeparams: [{hkr_class_id:'hkr_class_id'},{type:'hkr_type'},{course_name:'hkr_course_name'},{course_id:'hkr_course_id'}] },
      { title: '成绩', breadcrumb: true }
    ],
  },
  {
    path: '/course/plist/train/select/record',
    name: 'CourseTrainSelectrecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect_record/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true,  }, 
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] },
      { title: '练习列表',url: "/mytrainingclass/trainexamlist", breadcrumb: true, routeparams: [{hkr_class_id:'hkr_class_id'},{type:'hkr_type'},{course_name:'hkr_course_name'},{course_id:'hkr_course_id'}] },
      { title: '历史记录', breadcrumb: true }
    ],
  },
  {
    path: '/course',
    name: 'course',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/course/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true,  }, 
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] }, // params: class_id 上一级参数 ['hkr_class_id'] 本页参数，但是是此上级路由接收参数
      { title: '我的课程', breadcrumb: true }
    ],
  },

  {
    path: '/course/train/select/record',
    name: 'TrainSelectrecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect_record/index.vue'),
    meta: [
      { title: '我的培训', url: "/mytrainingclass", breadcrumb: true,  }, 
      { title: '课程列表', url: "/mytrainingclass/courselist", breadcrumb: true, routeparams: [{class_id:'hkr_class_id'}] },
      { title: '我的课程', url: "/course", breadcrumb: true, routeparams: [{hkr_class_id:'hkr_class_id'},{course_id:'hkr_course_id'}] },
      { title: '历史记录', breadcrumb: true }
    ],
  },

  {
    path: '/mytrainingclass/currentexam',
    component: () => import('@/views/security_currentexam/index.vue'),
    name: 'mytrainingclass_currentexam',
    meta: [  { title: '当前考试', url: "/mytrainingclass/currentexam", breadcrumb: true },
          ]
  },
  {
    path: '/currentexam/train/select',
    name: 'CurrentExamTrainSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect/index.vue'),
    meta: [
      { title: '当前考试', url: "/mytrainingclass/currentexam", breadcrumb: true, },
      { title: '试卷页面', breadcrumb: true }
    ],
  },
  {
    path: '/train/select',
    name: 'TrainSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_trainselect/index.vue'),
    meta: [
      { title: '知识练习', url: "/knowledgetrain", breadcrumb: true, },
      { title: '试卷页', breadcrumb: true }
    ],
  },
  {
    path: '/train/select/record',
    name: 'TrainSelectrecord',
    component: () => import( '../views/security_trainselect_record/index.vue'),
    meta: [
      { title: '知识练习', url: "/knowledgetrain", breadcrumb: true },
      { title: '历史记录', breadcrumb: true }
    ],
  },
  {
    path: '/errorbooks',  /* 有历史记录按钮*/
    name: 'ErrorBooks',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_errorbooks/index.vue'),
    meta: [
      { title: "错题本", breadcrumb: true }
    ],
  },
  {
    path: '/errorbooks/select/record',
    name: 'ErrorBooksSelectrecord',
    component: () => import( '../views/security_trainselect_record_onlyone/index.vue'),
    meta: [
      { title: '错题本', url: "/errorbooks", breadcrumb: true },
      { title: '详情页', breadcrumb: true }
    ],
  },
  {
    path: '/scoreshow',
    name: 'ScoreShow',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_scoreshow/index.vue'),
    meta: [
      { title: '知识练习', url: "/knowledgetrain", breadcrumb: true },
      { title: '成绩', breadcrumb: true }
    ],
  },
  {
    path: '/building',
    name: 'Building',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_buildingload/index.vue'),
    meta: [
      { title: '页面构建中...',  breadcrumb: true },
    ],
  },

  {
    path: '/notice/list',
    name: 'noticeList',
    component: () => import(/* webpackChunkName: "about" */ '../views/security_notice_list/index.vue'),
    meta: [
      { title: "公告列表",  breadcrumb: true },
    ],
  },
  {
    path: '/theory',
    name: 'Theory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/security_knowledge_theory/index.vue'),
    meta: [{ title: '理论知识', breadcrumb: true }]   //有翻译的

  },
  {
    path: '/theory/course',
    name: 'TheoryCourse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/security_knowledge_theory_course/index.vue'),
    meta: [
      { title: '理论知识', url: "/theory", breadcrumb: true,  }, 
      { title: '我的课程', breadcrumb: true }
    ],
  },
  {
    path: '/',
    name: 'Home',
    // component: Home
    redirect: '/login'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index.vue')
  },
  {
    path: '/home/course',
    name: 'HomeCourse',
    component: HomeCourse
  },
  {
    path: '/home/generalquestions',
    name: 'HomeGeneralQuestions',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeGeneralQuestions.vue')
  },
  {
    path: '/home/about',
    name: 'HomeAbout',
    component: HomeAbout
  },
    //反馈
    {
      path: '/Feedback',
      name: 'feedback',
      component: () => import('../views/feedback/index.vue'),
    },
  {
    path: '/user',
    name: 'UsesrCenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },


  {
    path: '/coursemain/index',
    name: 'coursepartsindexdel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/coursemaindel/index.vue')
  },
  {
    path: '/coursemain/parts',
    name: 'courseparts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/courseparts/index.vue')
  },

  {
    path: '/coursemain/pparts',
    name: 'coursepartssingle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/coursepartsingle/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  {
    path: '/coursemain/train',
    name: 'coursetrain',
    component: () => import(/* webpackChunkName: "about" */ '../views/coursetrain/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  //短语和术语
  {
    path: '/phraseortermin',
    name: 'phraseortermin',
    component: () => import(/* webpackChunkName: "about" */ '../views/phraseortermin/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/phraseortermin/books',
    name: 'phraseorterminbooks',
    component: () => import(/* webpackChunkName: "about" */ '../views/phraseorterminbooks/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/phraseortermin/card',
    name: 'phraseortermincard',
    component: () => import(/* webpackChunkName: "about" */ '../views/phraseortermincard/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  //文献资料
  {
    path: '/literature',
    name: 'literature',
    component: () => import(/* webpackChunkName: "about" */ '../views/literature/index.vue'),
    meta: [{ title: 'icao_common.literature', breadcrumb: true }],
  },
  {
    path: '/trainmain',
    name: 'trainmain',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainmain/index.vue'),
    meta: [{ title: 'icao_common.specifictraining', breadcrumb: true }],
  },
  {
    path: '/trainmain/listen',
    name: 'TrainListen',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlisten/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenb',
    name: 'TrainListenb',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenb/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenerror',
    name: 'TrainListenError',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenError/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', breadcrumb: true },
    ],
  },
  {
    path: '/trainmain/listenerrorblank',
    name: 'TrainListenErrorBlank',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenErrorBlank/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.wrongtopic', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenerrorselect',
    name: 'TrainListenErrorSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenErrorSelect/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.wrongtopic', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenerrorqa',
    name: 'TrainListenErrorQa',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenErrorQa/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.wrongtopic', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listen/blank',
    name: 'TrainListenBlank',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenblank/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.fillintheblanks', breadcrumb: true }
    ],
  },

  {
    path: '/trainmain/listen/qa',
    name: 'TrainListenQa',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenqa/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.listeningandspeaking', breadcrumb: true }
    ],
  },
  // 九百句
  {
    path: '/trainmain/ninehundred',
    name: 'trainninehundred',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundred/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'PEPEC 900', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredhelper',
    name: 'trainninehundredhelper',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredhelper/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'PEPEC 900', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredtest',
    name: 'TrainListenNinehundredTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredtest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.pepec900selftesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredteststart',  /* 有历史记录按钮*/
    name: 'TrainListenNinehundredTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.pepec900selftestingstart', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredtest/history',  /* 有历史记录按钮*/
    name: 'TrainListenNinehundredTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredtesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.pepec900selftesting", url: "/trainmain/ninehundredteststart", breadcrumb: true },
      { title: 'na_common.history', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredtest/history/score',  /* 有历史记录按钮*/
    name: 'TrainListenNinehundredTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredtesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.pepec900selftesting", url: "/trainmain/ninehundredteststart", breadcrumb: true },
      { title: "na_common.history", url: "/trainmain/ninehundredtest/history", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredtest/score',
    name: 'TrainListenNinehundredTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredtestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'PEPEC 900 Self-testing/score', breadcrumb: true }
    ],
  },
  //听力
  {
    path: '/trainmain/listentest',
    name: 'TrainListenTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistentest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'Listening Self-testing', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenteststart',  /* 有历史记录按钮*/
    name: 'TrainListenTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'Listening Self-testing Start', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listentest/history',  /* 有历史记录按钮*/
    name: 'TrainListenTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistentesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "Listening Self-testing", url: "/trainmain/listenteststart", breadcrumb: true },
      { title: 'History', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listentest/history/score',  /* 有历史记录按钮*/
    name: 'TrainListenTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistentesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "Listening Self-testing", url: "/trainmain/listenteststart", breadcrumb: true },
      { title: "History", url: "/trainmain/listentest/history", breadcrumb: true },
      { title: 'Score', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listentest/score',
    name: 'TrainListenTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistentestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'Listening Self-testing/score', breadcrumb: true }
    ],
  },

  //情景
  {
    path: '/trainmain/src',
    name: 'TrainSRC',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrc/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/src/atis',
    name: 'TrainSRCATIS',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcatis/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication', url: "/trainmain/src", breadcrumb: true },
      { title: 'ATIS', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/src/roleplay',
    name: 'TrainSRCRolePlay',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplay/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication', url: "/trainmain/src", breadcrumb: true },
      { title: 'icao_common.roleplayexcercises', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/src/roleplaytest',
    name: 'TrainSRCRolePlayTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplaytest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication-selftesting-start', url: "/trainmain/roleplayteststart", breadcrumb: true },
      { title: 'icao_common.roleplayexcercisesselftesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/roleplayteststart',  /* 有历史记录按钮*/
    name: 'TrainSRCRolePlayTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplayteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication-selftesting-start', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/src/roleplaytest/score',
    name: 'TrainSRCRolePlayTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplaytestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication-selftestingscore', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/roleplaytest/history',  /* 情景历史*/
    name: 'TrainSRCRolePlayTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplaytesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.simulatingradiotelephonycommunication-selftesting", url: "/trainmain/roleplayteststart", breadcrumb: true },
      { title: 'na_common.history', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/roleplaytest/history/score',  /* 情景历史分数*/
    name: 'TrainSRCRolePlayTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplaytesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.simulatingradiotelephonycommunication-selftesting", url: "/trainmain/roleplayteststart", breadcrumb: true },
      { title: "na_common.history", url: "/trainmain/roleplaytest/history", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  //训练设备检测
  {
    path: '/trainmain/listen/testdevice',
    name: 'TrainListenTextDevice',
    component: () => import(/* webpackChunkName: "about" */ '../views/listentestdevice/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "na_common.equipmenttesting", breadcrumb: true },
    ],
  },
  //训练面试
  {
    path: '/trainmain/interviewlist',
    name: 'TrainInterviewList',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewlist/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interview',
    name: 'TrainInterview',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterview/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interviewtest',
    name: 'TrainInterviewTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewtest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interviewteststart',  /* 有历史记录按钮*/
    name: 'TrainInterviewTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftestingstart', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interviewtest/score',
    name: 'TrainInterviewTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewtestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftestingscore', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interviewtest/history',
    name: 'TrainInterviewTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewtesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.oralproficiencyinterviewselftesting", url: "/trainmain/interviewteststart", breadcrumb: true },
      { title: 'na_common.history', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interviewtest/history/score',
    name: 'TrainInterviewTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewtesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.oralproficiencyinterviewselftesting", url: "/trainmain/interviewteststart", breadcrumb: true },
      { title: "na_common.history", url: "/trainmain/interviewtest/history", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  {
    path: '/train/history/score',
    name: 'TrainHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainhistoryscore/index.vue'),
    meta: [
      { title: 'na_common.history', url: "/pepec/history", breadcrumb: true },
      // { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  //训练故事复诵 路由
  {
    path: '/trainmain/story',
    name: 'TrainStory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.retellingstory', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storytest',
    name: 'TrainStoryTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstorytest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.retellingstoryselfstesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storytest/score',
    name: 'TrainStoryTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstorytestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.retellingstoryselfstestingscore', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storyteststart',  /* 有历史记录按钮*/
    name: 'TrainStoryTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstoryteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.retellingstoryselfstestingstart', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storytest/history',  /* 有历史记录按钮*/
    name: 'TrainStoryTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstorytesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.retellingstoryselfstesting", url: "/trainmain/storyteststart", breadcrumb: true },
      { title: 'na_common.history', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storytest/history/score',  /* 有历史记录按钮*/
    name: 'TrainStoryTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstorytesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.retellingstoryselfstesting", url: "/trainmain/storyteststart", breadcrumb: true },
      { title: "na_common.history", url: "/trainmain/storytest/history", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  //考试部分
  {
    path: '/examlist',
    name: 'ExamList',
    component: () => import(/* webpackChunkName: "about" */ '../views/examlist/index.vue'),
    meta: [
      { title: "icao_common.simulation", breadcrumb: true },
    ],
  },
  {
    path: '/examlist/testdevice',
    name: 'ExamListTextDevice',
    component: () => import(/* webpackChunkName: "about" */ '../views/examtestdevice/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "Equipment Testing", breadcrumb: true },
    ],
  },
  {
    path: '/examlist/instructions',
    name: 'ExamListInstructions',
    component: () => import(/* webpackChunkName: "about" */ '../views/examinstructions/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "INSTRUCATIONS", breadcrumb: true },
    ],
  },
  {
    path: '/exam/listen',
    name: 'ExamListenTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examlistentest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "icao_common.listeningcomprehension", breadcrumb: true }
    ],
  },
  {
    path: '/exam/listen/score',
    name: 'ExamListenTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examlistentestscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Listening Comprehension/Score', breadcrumb: true }
    ],
  },
  {
    path: '/exam/repeatingtest',
    name: 'ExamRepeatingTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examrepeatingtest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Repeating Radiotelephony Sentences', breadcrumb: true }
    ],
  },
  {
    path: '/exam/repeatinghot',
    name: 'ExamRepeatingHot',
    component: () => import(/* webpackChunkName: "about" */ '../views/examrepeatinghot/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Repeating Radiotelephony Sentences', breadcrumb: true }
    ],
  },
  {
    path: '/exam/repeatingtest/score',
    name: 'ExamRepeatingTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examrepeatingtestscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Repeating Radiotelephony Sentences/Score', breadcrumb: true }
    ],
  },
  {
    path: '/exam/roleplaytest',
    name: 'ExamRolePlayTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examsrcroleplaytest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'icao_common.roleplayexcercises', breadcrumb: true }
    ],
  },
  {
    path: '/exam/roleplaytest/score',
    name: 'ExamRolePlayTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examsrcroleplaytestscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'icao_common.roleplayexcercises', breadcrumb: true }
    ],
  },
  {
    path: '/exam/storytest',
    name: 'ExamStoryTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examstorytest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Retelling Story', breadcrumb: true }
    ],
  },
  {
    path: '/exam/storytest/score',
    name: 'ExamStoryTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examstorytestscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Retelling Story/Score', breadcrumb: true }
    ],
  },

  {
    path: '/exam/history',  /* 有历史记录按钮*/
    name: 'ExamHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/examhistory/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "na_common.history", breadcrumb: true }
    ],
  },
  {
    path: '/exam/history/score',  /* 有历史记录按钮*/
    name: 'ExamHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examhistoryscore/index.vue'),
    meta: [
      // { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "na_common.history", url: "/pepec/history", breadcrumb: true },
      { title: "na_common.score", breadcrumb: true }
    ],
  },
  {
    path: '/exam/total/score',  /* 有历史记录按钮*/
    name: 'ExamTotalScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examtotalscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'TotalScore', breadcrumb: true }
    ],
  },
  {
    path: '/selfsubmitqa',  /* 有历史记录按钮*/
    name: 'SelfSumbitQA',
    component: () => import(/* webpackChunkName: "about" */ '../views/selfsubmitqa/index.vue'),
    meta: [
      // { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "na_common.menuaddquestion", breadcrumb: true }
    ],
  },

  {
    path: '/onlinebookqa',  /* 在线答疑记录按钮*/
    name: 'OnLineBookQa',
    component: () => import(/* webpackChunkName: "about" */ '../views/onlinebookqa/index.vue'),
    meta: [
      { title: "na_common.scheduleqa",breadcrumb: true },
    ],
  },
  



]

const router = new VueRouter({
  routes
})

export default router
