<template>
  <header>
    <div style="height: 45px; background-color: rgba(35, 74, 102, 1)">
      <div style="width: 1200px; margin: 0 auto; color: white" class="icaoheader">
        <div style="float: left;margin:0 10px" >
          <span
            v-if="this.$store.state.user.name != ''"
            style="color: white; line-height: 45px; font-size: 14px"
            >欢迎  <a
            @click="checktocourse()"
            style="
              color: white;
              line-height: 45px;
              font-size: 14px;
              cursor: pointer;
            "
            >{{ this.$store.state.user.name }}</a> </span
          >  
        </div>
        <div style="float: left;margin:0 10px" class="bgcolor">
          
          
          <el-tooltip placement="bottom" effect="light">
  <div slot="content">
    <!-- <div style="text-align:center; float:left"><img width="200" :src="apkcode_url" /><br /> 安卓APP(内测版)</div> -->
    <div style="text-align:center; float:left"><img width="200" :src="apkcode_url" /><br /> 扫一扫</div>
  </div>
   <a
            href="#####"
            style="color: white; line-height: 45px; font-size: 14px"
            >APP客户端 <i class="el-icon-arrow-down"></i></a>
</el-tooltip>
        </div>
        <div style="float: right;margin:0 10px" v-if="this.$store.state.user.name != ''">
          <div class="bgcolor">
          <a
            @click="checktocourse()"
            style="
              color: white;
              line-height: 45px;
              font-size: 14px;
              cursor: pointer;
            "
            >学习中心</a
          >
          </div>
          <div style="float:left;line-height:40px">
          <el-divider direction="vertical" ></el-divider>
          </div>
          <div class="bgcolor">
          <a
            @click="logout"
            style="
              color: white;
              line-height: 45px;
              font-size: 14px;
              cursor: pointer;
            "
            >退出</a
          >
          </div>
        </div>
        <div style="float: right" v-else>
          <div class="bgcolor">
          <a
            @click="$router.push({ path: '/login?redirect=' + activeIndex })"
            style="
              color: white;
              line-height: 45px;
              font-size: 14px;
              cursor: pointer;
            "
            >登录</a
          >
          </div>
          <div style="float:left;line-height:40px">
          <el-divider direction="vertical" ></el-divider>
          </div>
          <div class="bgcolor">
          <a
            @click="$router.push({ path: '/login?redirect=' + activeIndex })"
            style="
              color: white;
              line-height: 45px;
              font-size: 14px;
              cursor: pointer;
            "
            >注册</a
          >
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        height: 103px;
        width: 1200px;
        margin: 0 auto;
        color: rgba(28, 120, 204, 0.996078431372549);
        padding-top: 10px;
      "
    >
      <!-- <div
        style="float: left; font-size: 18px; font-family: '隶书', sans-serif"
      >
        <h1 style="cursor: pointer" @click="$router.push({ path: '/' })">
          航科在线
        </h1>
      </div> -->
       <div
        style="float: left;padding-top:25px"
      >
        <img src="@/assets/logo.jpg" height="47"  />
      </div>

      <div
        class="el-menu-demo"
        style="
          float: right;
          font-size: 12px;
          line-height: 50px;
          padding-top: 15px;
          padding-right: 5px;
        "
      >
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          router
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/home/vip">VIP特权</el-menu-item>
          <!-- <el-submenu index="2">
            <template slot="title">专业</template>
            <el-menu-item index="/home/course?cate=0">
              <i class="el-icon-s-promotion"></i> 飞行英语</el-menu-item
            >
            <el-menu-item index="/home/course?cate=1">
              <i class="el-icon-setting"></i> 机务英语</el-menu-item
            >
            <el-menu-item index="/home/course?cate=2">
              <i class="el-icon-set-up"></i> 管制英语</el-menu-item
            >
            <el-menu-item index="/home/course?cate=3">
              <i class="el-icon-service"></i> 空乘英语</el-menu-item
            >
          </el-submenu> -->
          <el-menu-item index="/literature">规章文献查询</el-menu-item>
        </el-menu>
      </div>
    </div>
  </header>
</template>

<script>
import { removeToken } from '@/utils/auth'
import { getlastver } from '@/api/head'

export default {
  name: 'homeheader',
  computed: {},
  props: {},
  data() {
    return {
      apkcode_url: '',
      activeIndex: '/',
      title: '航科在线安检培训考核系统',
    }
  },
  created() {
    getlastver().then((response) => {
        // this.apkcode_url = response.Android_data.apkcode_url
        this.apkcode_url = response.download
      })

    if (this.$route.name == 'Home') {
      this.activeIndex = '/'
    } else if (this.$route.name == 'HomeCourse') {
      this.activeIndex = '/home/course?cate=' + this.$route.query.cate
       } else if (this.$route.name == 'HomeVIP') {
      this.activeIndex = '/home/vip'
    } else if (this.$route.name == 'literature') {
      this.activeIndex = '/literature'
    } else if (this.$route.name == 'HomeAbout') {
      this.activeIndex = '/home/about'
    }
  },
  methods: {
    checktocourse() {
      var url = ''
      this.roles = this.$store.state.user.roles
      try {
        this.roles.forEach((element) => {
          
          if (element == '1') {
            url = 'coursemain'
            throw Error()
          } else if (element == '2') {
            url = 'jwcoursemain'
            throw Error()
          } else if (element == '3') {
            url = 'kgcoursemain'
            throw Error()
          }else{
            url = 'Dashboard'
            throw Error()
          }
        })
      } catch (e) {
        //console.log()
        }

      this.$router.push({
        name: url,
        query: {},
      })
    },
    handleSelect() {},
    logout() {
      removeToken()
      this.$store.dispatch('user/logout')
      this.$router.push(`/`)
    },
  },
}
</script>
<style  lang="scss" scoped>
body a {
  text-decoration: none;
}
.icaoheader .bgcolor{
   float: left;
   padding: 0 10px;
}
.icaoheader .bgcolor:hover{
   background: #017a9b;
   
}
.el-menu-demo {
  ::v-deep .el-menu.el-menu--horizontal {
    border-bottom: solid 1px white;
  }
  ::v-deep li,
  ::v-deep .el-submenu__title {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>