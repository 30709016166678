<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getLanguage,setLanguage } from '@/utils/language' 
export default {
  name: 'App',
  // data() {
  //   return {
  //      examrouterule: [{"selfname":"ExamListenTest","toname":"ExamListenTestScore"}]
  //   }
  // },
  // watch: {
  //   $route: {
  //     immediate: true, // 一旦监听到路由的变化立即执行
  //     handler(to, from) {
  //       console.log("监听路由：" + JSON.stringify(to.name));
  //       console.log("监听路由：" + JSON.stringify(from.name));
  //       if(to.name == "ExamListenTest" && from.name == "ExamListInstructions"){ //
  //         const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  //         if (!answer) return false
  //       }
  //     },
  //   },
  // },
  created() {
    localStorage.setItem('isstreamasr',true)
    if(getLanguage() == undefined){
        setLanguage('zh')
    }
  },
}
</script>

<style>
html,body{
  margin:0;
  height: 100%;
  }
#app{
  height: 100%;
  }
</style>